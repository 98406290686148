/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-07-08 10:55:35
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-04 14:24:30
 * @FilePath: /mediatom-web/src/viewsForManage/dashboard/models/realTimeCard/boardData.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const boardList = [
  {
    valueProp: 'yesterdayRevenue',
    color: '#ffc327',
    label: '昨日收入'
  },
  {
    valueProp: 'sevenDaysRevenue',
    color: '#ff8f6b',
    label: '七日收入'
  },
  {
    valueProp: 'monthRevenue',
    color: '#605bff',
    label: '本月收入'
  },
  {
    valueProp: 'yearRevenue',
    color: '#5b93ff',
    label: '年收入'
  },
  {
    valueProp: 'sumRevenue',
    color: '#ff82ac',
    label: '总计收入'
  }
]
